import { lazy } from "react";
import ChangePassword from "./ChangePassword";
import { authRoles } from "src/app/roles";
import Crud from "src/@bootstrap/components/Crud/Crud";

const accountRoutes = [
    {
        path: "account/access-logs",
        element: <AccessLogs />,
        // auth: authRoles.admin
    },
    {
        path: "account/change-password",
        element: <ChangePassword />,
        // auth: authRoles.admin
    },
];

function AccessLogs() {
    return <Crud module='account' entity='access-logs' />;
};

export default accountRoutes;
