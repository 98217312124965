import "@ai4/form-builder/dist/index.css";
import { useState } from "react";
import { Modal } from "react-bootstrap";

function AddTenant(props) {
    const { save, addTenant, setAddTenant } = props;
    const handleClose = () => setAddTenant(false);
    const [id, setId] = useState<string>("");
    const [connectionString, setConnectionString] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [adminEmail, setAdminEmail] = useState<string>("");
    const [issuer, setIssuer] = useState<string>("");

    const onEnter = () => {
        setId("");
        setConnectionString("");
        setName("");
        setAdminEmail("");
        setIssuer("");
    }

    return (
        <>
            <Modal
                show={addTenant}
                onHide={handleClose}
                onEnter={onEnter}
                className="details-modal"
                size="xl"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="tab-content">
                            <div id="root-tabpane-Titolo" role="tabpanel" aria-labelledby="root-tab-Titolo" className="fade tab-pane active show">
                                <div id="columns.ujt3zphi0" className="columns row">
                                    <div className="col-6"><div className="mb-3">
                                        <label className="form-label" htmlFor="id">ID</label>
                                        <div className="Field-input">
                                            <input name="id" className="form-control " type="text" value={id} onChange={e => setId(e.target.value)} />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="column.zc4ljhrn4">Connection String
                                            </label>
                                            <div className="Field-input">
                                                <input name="connectionString" className="form-control " type="text" value={connectionString} onChange={e => setConnectionString(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="columns.nikevbtah" className="columns row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="column.2q208gg4e">Nome</label>
                                            <div className="Field-input">
                                                <input name="name" className="form-control " type="text" value={name} onChange={e => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="columns.f058c4ruy" className="columns row">
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="column.62mi0hmk8">Admin Email</label>
                                            <div className="Field-input">
                                                <input name="adminEmail" className="form-control " type="email" value={adminEmail} onChange={e => setAdminEmail(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="column.35z1nzmc2">Issuer</label>
                                            <div className="Field-input">
                                                <input name="issuer" className="form-control " type="text" value={issuer} onChange={e => setIssuer(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hstack gap-3 minBreakpoint-xs">
                            <button onClick={() => handleClose()} type="button" className="btn btn-secondary">Annulla</button>
                            <button
                                onClick={() => save({ id: id, name: name, connectionString: connectionString, adminEmail: adminEmail, issuer: issuer })}
                                type="button"
                                className="ms-auto btn btn-primary"
                            >
                                Salva
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddTenant;
