import React, { useState, useCallback, Fragment, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDataRequest } from "@ai4/data-request";
import { User } from "src/@bootstrap/types";
import { AppContext } from "src/@bootstrap/context";
import GullLayout from "src/app/template/GullLayout";
import { matchRouteByPath } from "src/@bootstrap/services/router";
import { loggedIn, loggedOut, selectAuthenticated, selectCurrentUser } from "src/@bootstrap/features/auth";
import Messages from "src/@bootstrap/template/partials/Messages";

function AuthGuard() {
    const [checking, setChecking] = useState(false);
    const { routes } = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    const auth = useSelector(selectAuthenticated);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const { useRestRequest } = useDataRequest();
    const [reqUser,] = useRestRequest({
        path: 'api/{ver}/personal/profile',
        method: 'GET',
        jwt: true,
    });

    const redirectRoute = useCallback(() => {
        dispatch(loggedOut());
        navigate("/session/signin", {
            state: { redirectUrl: location.pathname }
        });
    }, [location.pathname]);

    useEffect(() => {
        (async () => {
            try {
                if (auth) {
                    const user = await reqUser();
                    dispatch(
                        loggedIn({
                            ...(user as User),
                            role: "SA",
                        })
                    );
                }
            }
            catch (error) {
                dispatch(loggedOut());
                navigate("/session/signin", {
                    state: { redirectUrl: location.pathname }
                });
            }
        })();
    }, [auth]);

    useEffect(() => {
        setChecking(true);
        // find if user has authorization for this route
        const matched = matchRouteByPath(routes, location.pathname);
        const authenticated =
            matched && matched.auth && matched.auth.length
                ? (user ? matched.auth.includes(user.role) : false)
                : true;
        if (!authenticated) {
            redirectRoute();
        } else {
            setChecking(false);
        }
    }, [user, location.pathname, redirectRoute]);
    if (checking) return null;

    return (
        <Fragment>
            <Messages />
            <GullLayout />
        </Fragment>
    );
}

export default AuthGuard;