import { authRoles } from "src/app/roles";
import Permissions from '@ai4/ui-permissions';

const configRoutes = [
  {
    path: "config/roles/List",
    element: <Permissions type='roles' />,
    auth: authRoles.admin
  },
  {
    path: "config/permissions/main",
    element: <Permissions type='permissions' />,
    auth: authRoles.admin
  }
];

export default configRoutes;
