import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import { gql, useAuthRequest, useDataRequest, useFormViewer } from '@ai4/data-request';
import { textFilter } from '@ai4/react-bootstrap-table2-filter';
import $schema from './demo.schema.json';
import { isMobile } from 'react-device-detect';
import { addDateColumns } from 'src/@bootstrap/components/Crud/Crud';
let schema = $schema as SchemaTypes.Schema;

const QUERY = gql`
	query getBilanci {
		bilanciQuery {
			bilanci {
				list {
					uniqueId
					insertDate
					updateDate
					titolo
					descrizione
					data
					categoria {
						uniqueId
						descrizione
					}
					sottoCategoria {
						uniqueId
						descrizione
					}
					documento {
						uniqueId
						fileName
						fileLength
						fileExt
					}
          			enteDiPubblicazione {
						uniqueId
						descrizione
					}
				}
			}
			categorie {
				list {
					uniqueId
					descrizione
				}
			}
			sottoCategorie {
				list {
					uniqueId
					descrizione
				}
			}
		}
  		entiPubbliciQuery {
			enti {
				list {
					uniqueId
					insertDate
					updateDate
					descrizione
				}
			}
		}
	}
`;

const SAVE = gql`
  mutation addBilanci($data: BilanciInput) {
		bilanciMutation {
			 bilanci {
				save(bilancio: $data) {
					uniqueId
				}
			}
		}
	}
`;

const DELETE = gql`
	mutation deleteBilanci($uniqueId: String) {
		bilanciMutation {
			bilanci {
				delete(uniqueId: $uniqueId)
			}
		}
	}
`;

interface Props {

}

export const useModule = () => {
    const { useQuery, useMutation } = useDataRequest();
	const { data, refetch } = useQuery(QUERY);
	const [execSave, resSave] = useMutation(SAVE, {
		refetchQueries: [{ query: QUERY }],
	});
	const [execDelete, resDelete] = useMutation(DELETE, {
		refetchQueries: [{ query: QUERY }],
	});

	const { transformData } = useFormViewer({});

	const save = useCallback(async (values) => {
		const { uniqueId } = values;

		await execSave({
			variables: {
				data: {
					...transformData(values, {
						uniqueId: true,
						categoriaUniqueId: true,
						sottoCategoriaUniqueId: true,
						enteDiPubblicazioneUniqueId: true,
						titolo: true,
						descrizione: true,
						data: 'date',
					}),
					documentoUniqueId: _.get(values, 'documento.uniqueId'),
				},
			},
		});
		return values;
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId, name } = values;
		const ret = await execDelete({
			variables: {
				uniqueId: uniqueId,
			},
		});
	}, []);

	const rows = useMemo(() => {
		return _.get(data, 'bilanciQuery.bilanci.list', []).map((row: any) => {
			return {
				...row,
				categoriaUniqueId: _.get(row, 'categoria.uniqueId'),
			};
		});
	}, [data])

	schema = useMemo(() => {
        if (!data) return schema;
        return traverseSchema(schema, (n: SchemaTypes.SchemaNode) => {
            if (n.id && n.id === 'categoriaUniqueId') return {
                ...n,
                props: {
                    items: _.get(data, 'bilanciQuery.categorie.list', []).map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId }))
                }
            };
            return n;
        });
    }, [schema, data]);

	return {
		data: rows,
		schema,
		actions: {
			save,
			remove
		},
		results: {
			save: resSave,
			remove: resDelete,
		},
		table: {
			columns: addDateColumns([{
				dataField: 'uniqueId',
				text: 'ID',
				hidden: true
			}, {
				dataField: 'profile.name',
				text: 'Profile name',
				sort: true,
				filter: textFilter(),
			}, {
				dataField: 'username',
				text: 'User Name',
				sort: true,
				filter: textFilter()
			}, {
				dataField: 'email',
				text: 'E-mail',
				sort: true,
				filter: textFilter()
			}]),
		}
	};
}
