import React, {
    useState,
    useCallback,
    Fragment,
    useContext,
    useEffect,
    Component,
    Suspense,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppContext } from "src/@bootstrap/context";
import { setLayoutSettings, setDefaultSettings, selectSettings } from "src/@bootstrap/features/layout";
import { loggedOut, selectCurrentUser } from "src/@bootstrap/features/auth";

import { GullLayouts } from ".";
import Customizer from "./SharedComponents/Customizer";
import { matchRouteByID } from "src/@bootstrap/services/router";
import { useAuthToken } from "@ai4/data-request";

function GullLayout() {
    const { routes } = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    const settings = useSelector(selectSettings);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
	const { forget } = useAuthToken();

    const initAppDirection = useCallback(() => {
        setTimeout(() => {
            document.documentElement.setAttribute("dir", settings.dir);
        });
    }, [settings]);

    const logout = useCallback(() => {
        navigate("/session/signin");
        forget();
        dispatch(loggedOut());
    }, []);

    useEffect(() => {
        initAppDirection();
    }, [initAppDirection]);

    useEffect(() => {
        // initCodeViewer();
        // this.updateSettingsFromRouter();
    }, [location.pathname]);

    const Layout = GullLayouts[settings.activeLayout];
    const matched = matchRouteByID(routes, 'root');
    return (
        <Suspense fallback={null}>
            <React.Fragment>
                <Layout user={user} routes={matched!.children} onLogout={logout}></Layout>
                {/* settings && settings.customizer.show && <Customizer /> */}
            </React.Fragment>
        </Suspense>
    );
}

export default GullLayout;
