import React, { ReactElement } from "react";
import ReactDOM from 'react-dom';
import App from 'src/app/App';
import reportWebVitals from './reportWebVitals';
import './@bootstrap/template/styles/reset.scss';
import { globalConfig, globalConfigUrl } from "./configuration/config";
import axios from "axios";

axios.get(globalConfigUrl)
  .then((response) => {
    globalConfig.config = response.data;
    localStorage.setItem("REACT_APP_PRESET_USERNAME", globalConfig.config.REACT_APP_PRESET_USERNAME);
    localStorage.setItem("REACT_APP_PRESET_PASSWORD", globalConfig.config.REACT_APP_PRESET_PASSWORD);
    return <App config={globalConfig.config} />;
  })
  .catch(e => {
    return (
      <>
        <p style={{ color: "red", textAlign: "center" }}>Error while fetching global config</p>
        <App config={globalConfig.config} />
      </>
    );
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(
      reactElement,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
