import { textFilter, selectFilter, dateFilter } from "@ai4/react-bootstrap-table2-filter";
import { Formatters } from "@ai4/records-management";

export function filterDefault() {
    return { placeholder: 'Filtra'}
}

export function filterDocumentArgs() {
    return { 
        filter: textFilter(filterDefault()),
        filterValue: (cell: any) => {
            return cell.fileName;
        },
        formatter: Formatters.fileFormatter
    }
}

export function nodeContent(type: string) {
    if (type === 'file') return `{
        uniqueId
        fileName
        fileLength
        fileExt
    }`;
}

export function changeUrlParams(changeMap: Record<string, string>) {
    const url = new URL(window.location.href);
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    Object.keys(changeMap).forEach(k => {
        searchParams.set(k, changeMap[k]);
    });
    return `${url.pathname}?${searchParams.toString()}`;
}

export function removeHash() { 
    // eslint-disable-next-line no-restricted-globals
    history.pushState("", document.title, window.location.pathname + window.location.search);
}

export function pathToSlug(path: string) { 
    return path.replace(/\./g, '_')
}

export function testRepeatArray( arr: any[], num: number ) {
    let res = [] as any[];
    for (let i=0; i<num; i++) {
        res = [...res, ...arr];
    }
    return res;
}

export function strContains( haystack: string, needle: string ) {
    return haystack.toLowerCase().includes(needle.toLowerCase());
}

export function lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function formatDate(raw: string, format: string = 'full') {
    const [date, time] = raw.split('T');
    const [y, m, d] = date.split('-');
    const [hh, mm, ss] = time.split(':');
    switch (format) {
        case 'date':
            return `${d}/${m}/${y}`;
        case 'time':
            return `${hh}:${mm}`;
        case 'full':
            return `${d}/${m}/${y} ${hh}:${mm}`;
    }
}