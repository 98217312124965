import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Ruoli from './Ruoli';

export function UsersEdit(props: any) {
	const { children, record, dirty, show, extraData, onAddExtraData, handleClose, handleClosed } = props;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            onExited={handleClosed}
            size='xl'
        >
            <Modal.Body>
                <Ruoli values={record} onChange={(values) => { onAddExtraData(values); }} />
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default UsersEdit;
