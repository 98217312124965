import React from 'react';
import Root from 'src/@bootstrap/Root';
import "src/app/store";
import routes from "src/app/routes";
import theme from "src/app/theme";
import "src/app/styles/index.scss";

function App(config) {
    return (
        <Root
            routes={routes}
            theme={theme}
            dataProvider={{
                domain: 'default',
                domains: {
                    default: {
                        base: config.config.REACT_APP_API_BASE || "",
                        xAPIKey: config.config.REACT_APP_API_XAPIKEY || "",
                    },
                },
            }}
        />
    );
}

export default App;
