// config.ts
export interface DynamicConfig {
    REACT_APP_API_BASE:string;
    REACT_APP_API_XAPIKEY:string;
    REACT_APP_PRESET_USERNAME:string;
    REACT_APP_PRESET_PASSWORD:string;
  }
  
  export const defaultConfig: DynamicConfig = {
    REACT_APP_API_BASE:"https://ai4-api-wppa.pikkart.com/",
    REACT_APP_API_XAPIKEY:"root",
    REACT_APP_PRESET_USERNAME:"admin@root.com",
    REACT_APP_PRESET_PASSWORD:""
  };
  
  class GlobalConfig {
    config: DynamicConfig = defaultConfig;
  }
  
  export const globalConfig = new GlobalConfig();
  
  export const globalConfigUrl = "config.json";