import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDataRequest } from '@ai4/data-request';
import { Formatters } from '@ai4/records-management';

export const useModule = () => {
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/personal/access-logs',
        method: 'GET',
        jwt: true,
    });
    useEffect(() => {
        reqList();
    }, []);

	const rows = resList.data || [];

	return {
		data: rows,
		actions: {
		},
		results: {
		},
		extra: {
			hideEditColumns: true,
			slots: {
				header: () => null,
			}
		},
		table: {
			columns: [
				{
					dataField: 'accessDate',
					text: 'Accesso',
					formatter: Formatters.dateFormatter
				},
				{
					dataField: 'loginSuccess',
					text: 'Esito',
					formatter: (cell: any) => (cell ? 'positivo' : 'negativo'),
				},
			],
		},
	};
}
