import { useEffect, useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest, useFormViewer } from "@ai4/data-request";
import Table from "../../../@bootstrap/components/Table";
import Spinner from "../../../@bootstrap/components/Spinner";
import TenantsModal from "./modal/TenantsModal";
import AddTenant from "./AddTenant";
import TenantsFilters from "./TenantsFilters"
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'
import { formatDate } from "../../../@bootstrap/features/utilities";

function Tenant() {
    const [requestId, setRequestId] = useState<string>("");
    const [requestName, setRequestName] = useState<string>("");
    const [startPaginationLabel, setStartPaginationLabel] = useState<number>(0);
    const [endPaginationLabel, setEndPaginationLabel] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [addTenant, setAddTenant] = useState<boolean>(false);
    const [tenantsValue, setTenantsValue] = useState<Array<any>>([]);
    const [spinnerShow, setSpinnerShow] = useState<boolean>(false);

    const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>("");

    const [columns, setColumns] = useState<Array<any>>([
        { name: "Id", toggle: true },
        { name: "Name", toggle: true },
        { name: "AdminEmail", toggle: true },
        { name: "IsActive", toggle: false },
        { name: "ValidUpto", toggle: true },
        { name: "ApplicationUrl", toggle: true },
        { name: "ConnectionString", toggle: false },
        { name: "Issuer", toggle: false },
    ]);
    const [paginationValue] = useState<Array<string>>(["10", "25", "30", "50"]);
    const [defaultPage, setDefaultPage] = useState<string>(paginationValue[0]);
    const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
    const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
    const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
    const [name, setName] = useState<string>("");
    const [active, setActive] = useState<string>("");
    const { useRestRequest } = useDataRequest();
    const [reqList, resList] = useRestRequest({
        path: "api/{ver}/tenants",
        method: "GET",
        jwt: true,
    });
    const [execSave, resSave] = useRestRequest({
        path: "api/{ver}/tenants",
        method: "POST",
        jwt: true,
    });

    function filter(elementToFind1, elementToFind2, myArray) {
        let arrayFiltred: any = [];
        let nowDate = new Date().getTime();
        for (let i = 0; i < myArray.length; i++) {
            let validUptoDate = new Date(myArray[i].validUpto).getTime();
            if (elementToFind1 !== "" && elementToFind2 !== "") {
                if (myArray[i].isActive === elementToFind2 &&
                    myArray[i].name.toUpperCase() === elementToFind1.toUpperCase() &&
                    validUptoDate > nowDate) {
                    arrayFiltred.push(myArray[i]);
                }
            } else if (elementToFind1 !== "") {
                if (myArray[i].name.toUpperCase() === elementToFind1.toUpperCase()) {
                    arrayFiltred.push(myArray[i]);
                }
            } else if (elementToFind2 !== "") {
                if (myArray[i].isActive === elementToFind2 &&
                    validUptoDate > nowDate) {
                    arrayFiltred.push(myArray[i]);
                }
            } else {
                arrayFiltred.push(myArray[i]);
            }
        }
        return arrayFiltred;
    }

    const applyFilters = (name, active, tenantsValue) => {
        if (typeof tenantsValue !== "undefined") {
            let isActive = active === "true" ? true : active === "false" ? false : "";
            setTenantsValue(filter(name, isActive, tenantsValue));
        }
    };

    const callTenants = () => {
        reqList().then((data: any) => {
            setPaginationTotalCount(data.length);
            let startPagination = 0;
            if (parseInt(paginationCurrentPage) !== 1) {
                for (let index = 1; index < parseInt(paginationCurrentPage); index++) {
                    startPagination = startPagination + parseInt(defaultPage);
                }
            }
            let endPagination = (parseInt(defaultPage) * parseInt(paginationCurrentPage) < data.length ? parseInt(defaultPage) * parseInt(paginationCurrentPage) : data.length);
            setStartPaginationLabel(startPagination);
            setEndPaginationLabel(endPagination);
            applyFilters(name, active, data.slice(startPagination, endPagination));
            let tenantsTotalPages = Math.ceil(data.length / parseInt(defaultPage));
            setPaginationTotalPages(tenantsTotalPages.toString());
        }).catch((data: any) => {
            setIsErrorShow(true);
            setErrorMessage(data.messages);
            setErrorCode(data.requestId);
        });
    }

    useEffect(() => {
        callTenants();
    }, [defaultPage, paginationCurrentPage]);

    const { transformData } = useFormViewer({});

    const save = useCallback(async (values) => {
        const { id, name, connectionString, adminEmail, issuer } = values;

        await execSave({
            data: {
                ...transformData(values, {
                    id,
                    name,
                    connectionString,
                    adminEmail,
                    issuer,
                }),
            },
        }).catch((data: any) => {
            setIsErrorShow(true);
            setErrorMessage(data.messages);
            setErrorCode(data.requestId);
        });
        reqList();
        return values;
    }, []);

    const handleShowModal = (e) => {
        setRequestId(e.target.getAttribute("data-uniqueid"));
        setRequestName(e.target.getAttribute("data-name"));
        setShowModal(true);
    };

    // function to show or hide columns
    const onColumnToggle = (el) => {
        setColumns(
            columns.map(function (e) {
                if (e.name === el) {
                    e.toggle = !e.toggle;
                }
                return e;
            })
        );
    };

    // function to set table body from api
    const returnValuesRows = () => {
        return tenantsValue.map((data, index) => (
            <tr key={index}>
                {columns[0].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.id}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[1].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.name}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[2].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.adminEmail}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[3].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.isActive ? "True" : "False"}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[4].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {formatDate(data.validUpto)}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[5].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.applicationUrl}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[6].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.connectionString}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
                {columns[7].toggle ? (
                    <td
                        data-uniqueid={data.id}
                        data-name={data.name}
                        onClick={(e) => {
                            handleShowModal(e);
                        }}
                    >
                        {data.issuer}
                    </td>
                ) : (
                    <td style={{ display: "none" }}></td>
                )}
            </tr>
        ));
    };

    return (
        <>
            <TenantsFilters
                tenantsValue={tenantsValue}
                setTenantsValue={setTenantsValue}
                callTenants={callTenants}
                name={name}
                setName={setName}
                active={active}
                setActive={setActive}
            />

            <div className={true ? "table-container" : "d-none"}>
                <button onClick={() => setAddTenant(true)} className="btn btn-secondary">Aggiungi nuovo</button>
                <div className="table-container__label">Tenants</div>
                <Table
                    isLogTrace={false}
                    filters={false}
                    columns={columns}
                    onColumnToggle={onColumnToggle}
                    returnValuesRows={returnValuesRows}
                    paginationValue={paginationValue}
                    defaultPage={defaultPage}
                    setDefaultPage={setDefaultPage}
                    isStaticPagination={true}
                    paginationTotalCount={paginationTotalCount}
                    paginationTotalPages={paginationTotalPages}
                    paginationCurrentPage={paginationCurrentPage}
                    setPaginationCurrentPage={setPaginationCurrentPage}
                    startPaginationLabel={startPaginationLabel}
                    endPaginationLabel={endPaginationLabel}
                />
            </div>

            <TenantsModal
                requestId={requestId}
                requestName={requestName}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <AddTenant
                save={save}
                addTenant={addTenant}
                setAddTenant={setAddTenant}
            />

            <Spinner show={spinnerShow} />

            <ErrorApiAlert
                errors={errorMessage}
                errorCode={errorCode}
                isErrorShow={isErrorShow}
                setIsErrorShow={setIsErrorShow}
                setErrorMessage={setErrorMessage}
                setErrorCode={setErrorCode}
            />
        </>
    );
}

export default Tenant;
