import _get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'react-bootstrap';
import { useDataRequest } from "@ai4/data-request";
import { SchemaTypes, traverseSchema } from "@ai4/form-viewer";
import Schema from "@ai4/form-viewer/dist/types/schema";

export const useFormBuilderSchemaLoader = (mutation: string | string[]) => {
    const [schema, setSchema] = useState();
    const [schemas, setSchemas] = useState<Record<string, Schema>>();
    const { useRestRequest } = useDataRequest();
	const [req, res] = useRestRequest({
		path: `api/{ver}/forms`,
		jwt: true,
	});
    
    const loadSchema = async (path: string): Promise<Schema | null> => {
		if (!path) return null;
		const [group, ...rest] = path.split('.');
		const entity = rest.join('.');
		try {
			return await req({
				method: 'GET',
				queryString: {
					moduleNodeName: group,
					entityNodeName: entity,
				},
			}) as Schema;
            
		} catch (e) {
			return null
		}
	};

    useEffect(() => {
		(async () => {
			if (!Array.isArray(mutation)) {
				const json = await loadSchema(mutation);
				setSchema(json as any);
			} else {
				const schemas = await mutation.reduce<Promise<Record<string, Schema>>>(async (acc, path) => {
					let q = await acc;
					const schema = await loadSchema(path);
					if (schema) {
						q[path] = schema;
					}
					return q;
				}, Promise.resolve({}));
				setSchemas(schemas);
			}
		})();
    }, []);

	if (!Array.isArray(mutation)) {
    	return schema ? schema as SchemaTypes.Schema : null;
	} else {
		return schemas ? schemas : null;
	}
}

export const useFormBuilderSchemaMerger = (mutations: string[]) => {
	const schemas = useMemo(() => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return mutations.map(mutation => useFormBuilderSchemaLoader(mutation));
	}, [mutations]);
	const loading = schemas.filter(schema => schema === null).length > 0;
	if (loading) return null;
	return {
		defaultActiveTabId: "Titolo",
		id: "root",
		type: "tabs",
		tabs: [{
			id: "Titolo",
			title: "Titolo",
			rows: schemas.reduce<any>((acc, schema) => [...acc, ..._get(schema, 'tabs[0].rows', [])], [])
		}]
	} as SchemaTypes.Schema;
}

export function useUpdateSchemaNode(schema: Schema | null, nodeName: string, updater: (node: any) => any) {
	if (!schema) return null;

	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && name === nodeName) {
			return updater(n);
		}
		return n;
	});
}

type SelectItemsMap = Record<string, { text?: string, value?: string, creationURL?: string, listManagement?: { name: string; query_name: string; query_path: string; }, button?: () => JSX.Element }[]>;
interface SelectItemsOptions {
	noRefresh?: boolean;
}
export function useSelectItemsPopulate(schema: Schema | null, query: any, map: SelectItemsMap, options?: SelectItemsOptions) {
	const dispatch = useDispatch();
	const { data, refetch } = query;
	const { noRefresh } = options || {};
	if (!schema) return null;
    if (!data) return null; // schema;

	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && map[name]) {
			const { creationURL } = map[n.name].find(i => i.creationURL) || {};
			const { button } = map[n.name].find(i => i.button) || {};
			var action;
			if (creationURL) action = `${creationURL}#create`;
			if (button) action = button;
			return {
				...n,
				props: {
					...(n.props || {}),
					items: map[name].filter(i => !!i.value),
					creationURL: action,
					onRefresh: noRefresh ? undefined : () => {
						refetch();
					}
				}
			};
		}
		return n;
	});
}

export function useHideNodesSchema(schema: Schema | null, ids: string[]) {
	if (!schema) return null;
	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && ids.includes(name)) {
			return undefined;
		}
		return n;
	});
}

export function useKeepRequiredNodesSchema(schema: Schema | null, ids: string[]) {
	if (!schema) return null;
	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && ids.includes(name)) {
			return {
				...n,
				validation: [{
					rule: 'required',
				}]
			};
		}
		return n;
	});
}

export function useDataDecorator(path: string, data: any) {
	const fix = useCallback((row) => {
		return Object.keys(row).reduce((acc, k: string) => {
			const sub = row[k];
			if (Array.isArray(sub)) {
				acc[k] = sub.map(fix);
			} else if (sub && typeof sub === 'object') {
				const subId = _get(sub, 'uniqueId');
				if (subId) {
					acc[`${k}UniqueId`] = subId;
				}
				acc[k] = fix(sub);
			}
			return acc;
		}, {...row});
	}, []);

	const rows = useMemo(() => {
		return _get(data, path, []).map((row: any) => {
			return fix(row);
		});
	}, [data]);

	return rows;
}

interface UseTreeDecoratorArgs {
	labelField?: string;
}

export function useTreeDecorator(rows: any[], args?: UseTreeDecoratorArgs) {
	const { labelField = 'descrizione' } = args || {};
	const parents = rows.filter(row => !row.padre);
	const tree = parents.reduce((acc, parent) => {
		acc.push({
		  ...parent
		});
		return [
		  ...acc,
		  ...rows.filter(row => _get(row, 'padre.uniqueId') === parent.uniqueId).map(row => {
			return {
			  ...row,
			  [labelField]: `└── ${row[labelField]}`,
			}
		  })
		]
	}, []);
	return tree;
}

/***
 *	get versioned base url
 */
export function base(type: 'rest' | 'api' = 'rest') {
	return `${type}/v1`;
}

/***
 *	get content
 */
export function getRoot(schema) {
	return _get(schema, 'tabs[0].rows')
}