import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormViewer, SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import $schema from './ruoli.schema.json';
import { useDataRequest } from '@ai4/data-request';

let schema = $schema as SchemaTypes.Schema;

interface Props {
    values?: any;
    onChange: (values: any) => void;
}

const Ruoli = (props: Props) => {
    const { values, onChange } = props;
    const [current, setCurrent] = useState();

    const { useRestRequest } = useDataRequest();
    const [reqRoles, resRoles] = useRestRequest({
        path: 'api/{ver}/roles',
        method: 'GET',
        jwt: true,
    });
    const [reqUserRoles, ] = useRestRequest({
        path: 'api/{ver}/users/{id}/roles',
        method: 'GET',
        jwt: true,
    });

    const loadUserRoles = useCallback(async (id) => {
        const resUserRoles = await reqUserRoles({
            path: `api/{ver}/users/${id}/roles`,
            method: 'GET',
            jwt: true,
        }) as any;
        const $roles = resUserRoles //;
        setCurrent($roles.map(r => r.roleId));
        onChange({ $roles });
    }, []);
    
    useEffect(() => {
        if (values.id) loadUserRoles(values.id);
    }, [values]);

    useEffect(() => {
        reqRoles();
    }, []);

    schema = useMemo(() => {
        return traverseSchema(schema, (n: SchemaTypes.SchemaNode) => {
            if (n.id && n.id === '$roles') return {
                ...n,
                props: {
					multiple: true,
                    items: resRoles.data.map(role => ({ text: role.name, value: role.id }))
                }
            };
            return n;
        });
    }, [schema, resRoles]);

    if (!current) return null;
    return <FormViewer
        schema={schema}
        initialValues={{ $roles: current }}
        onChange={(args) => {
            const { values, form } = args;
            const { $roles } = values;
            onChange($roles.map(r => resRoles.data.find(r1 => r1.id === r)));
        }}
        slots={{
            ButtonBar: () => <></>,
        }}
    />
}

export default Ruoli;