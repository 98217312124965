import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import { gql, useAuthRequest, useDataRequest, useFormViewer } from '@ai4/data-request';
import { textFilter, dateFilter } from '@ai4/react-bootstrap-table2-filter';
import Avatar from 'src/@bootstrap/template/partials/Avatar';
import $schema from './user.schema.json';
import UsersEdit from './UsersEdit';
import { Form } from 'react-bootstrap';
let schema = $schema as SchemaTypes.Schema;

export const useModule = () => {
	const list = 'users.users.list';
	const mutation = "users.users";
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/users',
        method: 'GET',
        jwt: true,
    });
	const [execDetails, resDetails] = useRestRequest({
        path: 'api/{ver}/users/{id}',
        method: 'GET',
        jwt: true,
    });
	const [execSave, resSave] = useRestRequest({
        path: 'api/{ver}/users',
        method: 'POST',
        jwt: true,
    });
	const [execDelete, resDelete] = useRestRequest({
        path: 'api/{ver}/users/{id}',
        method: 'DELETE',
        jwt: true,
    });
	const [execSaveRoles, resSaveRoles] = useRestRequest({
        path: 'api/{ver}/users/{id}/roles',
        method: 'POST',
        jwt: true,
    });
    useEffect(() => {
        reqList();
    }, []);

	const { transformData } = useFormViewer({});
	const details = useCallback(async (row, args) => {
		const res = await execDetails({
			path: `api/{ver}/users/${row.id}`
		});
	}, []);

	const save = useCallback(async (values) => {
		const { id, userName, firstName, lastName, email, password, confirmPassword, $roles } = values;

		if (!id) {
			// at the moment can save only for creation, not update :(
			await execSave({
				data: {
					...transformData(values, {
						userName,
						firstName,
						lastName,
						email,
						password, confirmPassword
					}),
				},
			});
		}
		if (id) {
			await execSaveRoles({
				path: `api/{ver}/users/${id}/roles`,
				data: {
					userRoles: $roles
				},
			});
		}
        await reqList();
		return values;
	}, []);

	const remove = useCallback(async (values) => {
		const { id } = values;
		const ret = await execDelete({
			path: `api/{ver}/users/${id}`,
		});
        await reqList();
	}, []);

	const rows = resList.data || [];

	return {
		graphql: {
			list,
			mutation,
			loading: resList.loading,
			// details,
		},
		schema,
		dataList: rows,
		dataDetails: resDetails.data,
		actions: {
			save,
			remove,
			details: {
				onParseDataDetails: (data) => (data),
			},
			list: {
				onSelectedRow: details,
			}
		},
		results: {
			save: resSave,
			remove: resDelete,
		},
		extra: {
			// hideEditColumns: true,
			slots: {
				edit: UsersEdit,
			},
			options: {
				closeWhenSaved: true,
			},
		},
		table: {
			columns: [
				{
					dataField: 'id',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: 'avatar',
					text: '',
					align: "center",
					hidden: isMobile,
					headerStyle: { width: '50px' },
					formatter: (cell, row, rowIndex, formatExtraData) => {
						return (<img src={Avatar.generate(row.userName, "#003473", "white")} style={{ borderRadius: '50%' }} />);
					}
				},
				{
					dataField: 'name',
					text: 'name',
					formatter: (cell, row, rowIndex, formatExtraData) => {
						return `${row.lastName} ${row.firstName}`;
					}
				},
				{
					dataField: 'userName',
					text: 'userName',
				},
				{
					dataField: 'email',
					text: 'email',
				}, {
					dataField: 'isActive',
					text: 'Attivo',
					hidden: isMobile,
					sort: true,
					headerStyle: { width: '100px' },
					formatter: (cell, row, rowIndex, formatExtraData) => {
						return (<Form.Check
							type="switch"
							checked={row.isActive}
							onChange={() => { }}
							label=""
						/>);
					}
				},
			],
		}
	};
}
